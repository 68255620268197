import {
  LVBackgroundMotion,
  LVBackgroundStill,
} from "@/assets/json/LyricVideoBackgrounds";
import { GetLyricVideoSetlistsForOrgResponse } from "@/models/frontend-only/GetLyricVideoSetlistsForOrgResponse";
import {
  SSLyricVideo,
  SSLyricVideoIndex,
} from "@/models/frontend-only/LyricVideos";
import { LyricVideoCustomization } from "@/models/LyricVideoCustomization";
import { LyricVideoSetlist } from "@/models/LyricVideoSetlist";
import { LyricVideoSetlistItem } from "@/models/LyricVideoSetlistItem";

export const useLyricVideoMVPStore = defineStore("lyric-videos-mvp", () => {
  const profile = useProfileStore();
  const beta = useBetaStore();
  const api = useApiStore();
  const openedDropdowns = ref<string[]>([]);
  const dropdownOpen = computed(() => !!openedDropdowns.value.length);

  const backgroundImages = ref<LVBackgroundStill[]>([]);
  const backgroundVideos = ref<LVBackgroundMotion[]>([]);

  async function getLyricVideoBackgrounds() {
    const response: {
      images: LVBackgroundStill[];
      videos: LVBackgroundMotion[];
    } = await api.post("/GetLyricVideoBackgrounds");
    backgroundImages.value = response.images;
    backgroundVideos.value = response.videos;
  }

  const enable = computed(
    () =>
      (
        beta.features.lyricVideoMVP &&
        profile.profile?.featureToggles.enableLyricVideos) ||
      false,
  );
  const inBetaMode = computed(
    () => !!beta.featuresInBeta.includes("lyricVideoMVP") || false,
  );

  const setlistFeaturesEnabled = computed(
    () => profile.profile?.signedIn && profile.profile?.orgId,
  );

  const allVideos = ref<SSLyricVideoIndex[]>([]);
  const featuredVideos = ref<SSLyricVideoIndex[]>([]);
  const latestVideos = ref<SSLyricVideoIndex[]>([]);

  function onOpenDropdown(key: string) {
    if (!openedDropdowns.value.includes(key)) {
      openedDropdowns.value.push(key);
    }
  }

  async function onCloseDropdown(key: string) {
    await asyncWait(25);
    if (openedDropdowns.value.includes(key)) {
      openedDropdowns.value = openedDropdowns.value.filter((k) => k !== key);
    }
  }

  async function getSetlist(setlistId: string): Promise<LyricVideoSetlist> {
    return await api.post("/GetLyricVideoSetlist", {
      setListId: setlistId,
    });
  }

  async function getSetlists(
    pageNumber: number,
    pageSize: number,
    archivedLists: boolean,
  ) {
    if (!profile.profile?.orgId) {
      throw new Error("org id is missing");
    }

    const response: GetLyricVideoSetlistsForOrgResponse = await api.post(
      "/GetLyricVideoSetlistsForOrg",
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        archivedLists: archivedLists,
      },
    );

    return response;
  }

  async function getUpcomingSetlists() {
    const response = await getSetlists(1, 3, false);
    return response.summaryList;
  }

  async function getAllVideos() {
    await profile.profilePromise;
    if (!enable.value) {
      return;
    }
    if (allVideos.value.length > 0) {
      return;
    }
    const data: SSLyricVideoIndex[] = await api.post("/GetAllLyricVideos");
    allVideos.value = data;
    featuredVideos.value = data
      .filter((v) => v.featured)
      .sort((a, b) => (a.featuredOrder ?? 0) - (b.featuredOrder ?? 0));

    latestVideos.value = data
      .sort(
        (a, b) =>
          new Date(b.modified).getTime() - new Date(a.modified).getTime(),
      )
      .filter((a, i) => i < 5);
  }

  const prefetchedData: Record<string, SSLyricVideo> = {};

  async function prefetchLyricVideoData(videoId: string) {
    const data = await getLyricVideoData(videoId);
    prefetchedData[videoId] = data;
    return data;
  }

  function clearPrefetchedData() {
    Object.keys(prefetchedData).forEach((key) => {
      delete prefetchedData[key];
    });
  }

  async function getLyricVideoData(videoId: string) {
    if (prefetchedData[videoId]) {
      return prefetchedData[videoId];
    }

    console.log('mvp: gettin lyric video data', videoId);
    await profile.profilePromise;

    console.log('mvp: gettin lyric video data - profile ready', videoId);
    if (!enable.value) {
      throw new Error("Lyric Videos are not enabled");
    }

    console.log('mvp: waiting for all videos', allVideos.value.length);

    await waitUntilTrue(() => allVideos.value.length > 0);

    console.log('mvp: waiting for all videos - done', allVideos.value.length);

    const video = allVideos.value.find((v) => v.videoId.toLowerCase() === videoId.toLowerCase());

    console.log('mvp: waiting for all videos - done - found', video);
    if (!video){ 
      console.log('mvp: video not found', video);
      throw new Error("Video not found");
    }

    const response: SSLyricVideo = await api.post("/GetLyricVideoData", {
      releasePath: video.releasePath,
      videoId,
    });

    if (response.isPreview) {
      response.timemap.forEach((section) => {
        section.textTiming.forEach((t) => {
          t.start = t.start - response.sampleStart;
          if (t.end) {
            t.end = t.end - response.sampleStart;
          }
        });
      });
    }

    return response;
  }

  let firstVideoItem: LyricVideoSetlistItem | null = null;

  function createNewSetlist(firstVideo?: LyricVideoSetlistItem) {
    const modals = useModalStore();
    const date = new Date();

    modals.showModal("SetlistDetailsModal", {
      mode: "new",
      setListId: "new",
      setlistName: "",
      setlistNote: "",
      displaySongTitles: true,
      displaySongCopyrights: true,
      setlistDate: date.toISOString().split("T")[0],
      videos: (firstVideo && [firstVideo]) || [],
    });

    if (firstVideo) {
      firstVideoItem = firstVideo;
    } else {
      firstVideoItem = null;
    }
  }

  const router = useRouter();

  async function onNewSetlistCreated(data: {
    setListName: string;
    setListNote: string;
    setListDate: string;
    displaySongTitles: boolean;
    displaySongCopyrights: boolean;
    videos: LyricVideoSetlistItem[];
  }) {
    const payload = {
      title: data.setListName,
      description: data.setListNote || "",
      serviceDate: data.setListDate,
      setListMetadata: JSON.stringify({
        displaySongTitles: data.displaySongTitles,
        displaySongCopyrights: data.displaySongCopyrights,
      }),
      videos: JSON.stringify(data.videos),
    };

    const response: { setlistId: string } = await api.post(
      "/CreateLyricVideoSetlist",
      payload,
    );

    router.push(`/lyric-videos/setlists/${response.setlistId}?add=1`);
  }

  async function removeFromSetlist(setListId: string, setListItemId: string) {
    await api.post("/DeleteSetlistItem", {
      setListId,
      setListItemId,
    });
    toggleShowSavedStatus();
  }

  async function addToSetlist(setlistId: string, video: LyricVideoSetlistItem) {
    await api.post("/AddLyricVideoToSetlist", {
      setListId: setlistId,
      video: JSON.stringify(video),
    });
    (window as any).dataLayer.push({
      event: "addLyricVideoToSetlist",
    });
    toggleShowSavedStatus();

    // then take them to the page of interest
    // router.push(`/lyric-videos/setlists/${setlistId}?view=-1`);
  }

  const hasCustomizationsOnClipboard = ref(false);
  let customizationsClipboard: LyricVideoCustomization | null = null;

  function setCopyCustomizations(c: LyricVideoCustomization) {
    customizationsClipboard = cloneDeep(c);
    hasCustomizationsOnClipboard.value = true;
  }
  function getCopyCustomizations() {
    return customizationsClipboard;
  }

  async function updateSetlist(setlist: {
    setListId: string;
    setListName: string;
    setListNote: string;
    setListDate: string;
    displaySongTitles: boolean;
    displaySongCopyrights: boolean;
    videos: LyricVideoSetlistItem[];
  }) {
    await api.post("/UpdateLyricVideoSetlist", {
      setlistId: setlist.setListId,
      setListName: setlist.setListName,
      setListNote: setlist.setListNote,
      setListDate: setlist.setListDate,
      setListMetadata: JSON.stringify({
        displaySongTitles: setlist.displaySongTitles,
        displaySongCopyrights: setlist.displaySongCopyrights,
      }),
      videos: setlist.videos,
    });
    toggleShowSavedStatus();
    emitter.emit("refreshSetlistView");
  }

  async function deleteSetlist(setlistId: string) {
    await api.post("/DeleteSetlist", {
      setListId: setlistId,
    });
    emitter.emit("refreshSetlistView");
  }

  async function getCustomizations() {
    await waitUntilTrue(() => profile.profilePromise);

    if (!profile.profile) {
      return;
    }

    if (!profile.profile.signedIn) {
      return;
    }

    const savedData: string | null = await api.post(
      "/GetLyricVideoPreferences",
    );

    if (savedData) {
      browseCustomizations.value = JSON.parse(savedData);
    }
  }

  function saveCustomizations() {
    const response = api.post("/SaveLyricVideoPreferences", {
      lyricVideoCustomization: JSON.stringify(browseCustomizations.value),
    });
    toggleShowSavedStatus();
    return response;
  }

  const browseCustomizations = useLocalStorage(
    "lyric-video-browse-customizations",
    DefaultLVCustomization,
  );

  function updateSetlistOrder(
    setlistId: string,
    items: {
      setlistItemId: string;
      sortOrder: number;
    }[],
  ) {
    const response = api.post("/UpdateLyricVideoSetlistSortOrder", {
      setListId: setlistId,
      items: JSON.stringify(items),
    });
    return response;
  }

  function updateSetlistItem(
    setlistItemId: string,
    setlistId: string,
    customizations: LyricVideoCustomization,
  ) {
    const response = api.post("/UpdateLyricVideoSetlistItem", {
      setListItemId: setlistItemId,
      setListId: setlistId,
      customizations: JSON.stringify(customizations),
    });
    toggleShowSavedStatus();
    return response;
  }

  const showSavedStatus = ref(false);
  let showSavedStatusTimer: number | null = null;
  function toggleShowSavedStatus() {
    showSavedStatus.value = true;
    if (showSavedStatusTimer) {
      clearTimeout(showSavedStatusTimer);
    }
    showSavedStatusTimer = window.setTimeout(() => {
      showSavedStatus.value = false;
    }, 3000);
  }
  return {
    hasCustomizationsOnClipboard,
    setCopyCustomizations,
    getCopyCustomizations,
    createNewSetlist,
    updateSetlist,
    deleteSetlist,
    enable,
    allVideos,
    featuredVideos,
    getAllVideos,
    getSetlist,
    addToSetlist,
    removeFromSetlist,
    onNewSetlistCreated,
    latestVideos,
    getCustomizations,
    saveCustomizations,
    browseCustomizations,
    updateSetlistOrder,
    updateSetlistItem,
    dropdownOpen,
    onOpenDropdown,
    onCloseDropdown,
    getUpcomingSetlists,
    getSetlists,
    showSavedStatus,
    getLyricVideoData,
    backgroundImages,
    backgroundVideos,
    getLyricVideoBackgrounds,
    prefetchLyricVideoData,
    clearPrefetchedData,
    inBetaMode,
    setlistFeaturesEnabled,
  };
});
