import { ToastConfig } from "@/models/frontend-only/ToastConfig";

export const useToastStore = defineStore("toast", () => {
  const toasts = ref<ToastConfig[]>([]);

  function successToast(
    message: string,
    closable = false,
    persist = false,
    actions?: {
      message: string;
      emitName: string;
      emitParams?: any;
    }[],
  ) {
    createToast("success", message, closable, persist, actions);
  }

  function warningToast(
    message: string,
    closable = false,
    persist = false,
    actions?: {
      message: string;
      emitName: string;
      emitParams?: any;
    }[],
  ) {
    createToast("warning", message, closable, persist, actions);
  }

  function errorToast(
    message: string,
    closable = false,
    persist = false,
    actions?: {
      message: string;
      emitName: string;
      emitParams?: any;
    }[],
  ) {
    createToast("error", message, closable, persist, actions);
  }

  function createToast(
    variant: "success" | "error" | "warning" | "info",
    message: string,
    closable = false,
    persist = false,
    actions?: {
      message: string;
      emitName: string;
      emitParams?: any;
    }[],
  ) {
    const id = uniqueId("toast");
    toasts.value.push({
      id: id,
      closable,
      message,
      persist,
      variant,
      actions,
    });
    if (!persist) {
      removeToastAfterTimeout(id);
    }
  }

  async function removeToastAfterTimeout(id: string) {
    await new Promise<void>((res) => setTimeout(res, 5000));
    removeToast(id);
  }

  function removeToast(id: string) {
    const index = toasts.value.findIndex((t) => t.id === id);
    if (index >= 0) {
      toasts.value.splice(index, 1);
    }
  }

  return { toasts, successToast, errorToast, warningToast, removeToast };
});
