import { LyricVideoCustomization } from "../LyricVideoCustomization";

export interface TextTiming {
  text: string;
  start: number;
  end?: number;
}

export interface LyricVideoSection {
  partName: string;
  textTiming: TextTiming[];
}

export interface SSLyricVideoIndex {
  title: string;
  authors: string[];

  key: string;
  bpm: number;
  timeSignature: string;
  featured: boolean;
  featuredOrder: number | null;

  keyLyricSearchTerms: string[];
  ccliSongNumber: string;
  modified: string;
  leadVoice: string | null;
  duration: number;
  videoId: string;
  slideBackground: number | null;
  tags: string | null;
  releasePath: string;
}

export interface SSLyricVideo {
  sampleStart: number;
  sampleDuration: number;
  key: string;
  bpm: number;
  videoId: string;
  isPreview: boolean;
  timeSignature: string;
  modified: string;
  leadVoice: string | null;
  basedOn: {
    artists: string[];
    songName: string;
  };

  tracks: {
    name: string;
    url: string;
  }[];

  duration: number;
  featured: boolean;
  featuredOrder: number | null;
  timemap: LyricVideoSection[];

  /**
   * SongSelect Data
   */
  ccliSongNumber: string;
  title: string;
  authors: string[];
  catalogs: string[];
  alsoKnownAs: string[];
  copyrights: {
    territories: number[];
    copyrights: string[];
  }[];
  themes: string[];
  songSelectLinks: {
    songSelectSong: string;
    songSelectSongLyrics: string | null;
    songSelectChordSheet: string | null;
    songSelectLeadSheet: string | null;
    songSelectVocalSheet: string | null;
  };
  k: string;
  slideBackground: number | null;
  tags: string | null;
}

export interface SSLyricVideoSetlistIndex {
  title: string;
  description: string;
  createdTS: string;
  serviceDate: string;
  numVideos: number;
  setlistId: string;
}

export const DefaultLVCustomization: LyricVideoCustomization = {
  bgSolidColor: "black",
  bgSource: "solidcolor",
  fontColor: "white",
  fontSize: "25",
  fontFamily: "Sans-Serif",
  instrumentsVolume: "100",
  vocalVolume: "100",
  bgVideoKey: "block-gradient-purple",
  bgImageKey: "above-the-water-gray",
  leadingLyricTiming: 0.5,
  linesPerSlide: "1",
  textAlign: "center",
};
