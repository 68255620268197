<script setup lang="ts">
const store = useToastStore();
function handleAction(
  id: string,
  action: {
    emitName: string;
    emitParams: any;
  },
) {
  store.removeToast(id);
  emitter.emit(action.emitName as any, action.emitParams);
}
</script>

<template>
  <Teleport to="body">
    <div
      v-if="store.toasts.length"
      id="toastPlacement"
      class="toast toast-center"
    >
      <Alert
        v-for="t of store.toasts"
        :key="t.id"
        :variant="t.variant"
        :closable="t.closable"
        :config="t"
        @close="store.removeToast(t.id)"
      >
        {{ t.message }}
        <span
          v-if="t.actions"
          class="ml-2 inline-flex items-center gap-2"
        >
          <span
            v-for="(a, i) of t.actions"
            :key="i"
          >
            <button
              type="button"
              class="link"
              @click="
                handleAction(t.id, {
                  emitName: a.emitName,
                  emitParams: a.emitParams,
                })
              "
            >
              {{ a.message }}
            </button>
          </span>
        </span>
      </Alert>
    </div>
  </Teleport>
</template>

<style scoped lang="postcss">
.toast {
  z-index: 1000;
  @apply w-full max-w-xl;
}
</style>
