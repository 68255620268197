<script setup lang="ts">
import { categoryLUT, SearchCategory } from "@/assets/json/search-categories";

const winSize = useWindowSize();
const mobile = useMobileStore();
const { t } = useI18n();

const input = ref<HTMLInputElement>();
withDefaults(
  defineProps<{
    id?: string;
    alwaysShow?: boolean;
  }>(),
  {
    id: () => uniqueId("SearchTextInput"),
  },
);

const search = defineModel<string>("search", { default: "" });
const category = defineModel<SearchCategory>("category", {
  default: SearchCategory.All,
});

const emit = defineEmits<{
  (e: "submit"): void;
  (e: "blur"): void;
}>();

function onSubmit() {
  emit("submit");
  (window as any).dataLayer.push({
    event: "searchByCategory",
  });
}

const searchPlaceholder = computed(() =>
  winSize.width.value > 991
    ? t("SV.MES_SearchSongSelect")
    : t("SV.LIT_SearchSongSelect"),
);

function focus() {
  input.value?.focus();
}

function onBlur() {
  emit("blur");
}

defineExpose({
  focus,
});

const form = ref<HTMLFormElement>();

onClickOutside(form, async () => {
  if (!mobile.searchBarFocus) {
    return;
  }
  await nextTick();
  onBlur();
});
</script>

<template>
  <form
    ref="form"
    name="search-form"
    :class="{ 'always-show': alwaysShow }"
    @submit.stop.prevent="onSubmit"
  >
    <div class="input-wrapper">
      <Dropdown
        class="dropdown-category"
        disable-teleport
      >
        <template #btn-label>
          <template v-for="c in categoryLUT">
            <span
              v-if="category === c.value"
              :key="c.value"
              class="showing-category-label"
            >
              <i
                :class="c.icon"
                class="cat-icon"
              />
              {{ c.label }}
              <i class="fa-solid fa-caret-down caret-icon" />
            </span>
          </template>
        </template>
        <template #default>
          <DropdownItem
            v-for="c of categoryLUT"
            :key="c.value"
            :icon-before="c.icon"
            :active="category === c.value"
            @click="
              category = c.value;
              input?.focus();
            "
            >{{ c.label }}</DropdownItem
          >
        </template>
      </Dropdown>

      <label
        :for="id"
        class="hidden"
        >{{ $t("SV.LIT_SearchSongSelect") }}</label
      >
      <input
        :id="id"
        ref="input"
        v-model="search"
        type="text"
        class="input"
        :data-user-search-activity="'Search ' + category"
        :placeholder="searchPlaceholder"
      />
    </div>
  </form>
</template>

<style scoped lang="postcss">
form {
  @apply w-full;
}
form:not(.always-show) {
  @apply hidden lg:block;
}
form.always-show {
  @apply block;
}
input {
  @apply z-10 flex-1 rounded-r-full bg-light-500 py-2 focus:border-none;
  height: unset;
}
.input-search-btn {
  @apply absolute left-4 top-1/2 -mt-3 text-primary;
}
.input-wrapper {
  @apply relative flex items-center gap-[2px];
}
form.has-focus {
  @apply block;
}
.ignore-click {
  @apply pointer-events-none;
}
.showing-category-label {
  @apply flex items-center gap-2;
  i.cat-icon {
    @apply text-primary;
  }
}
.dropdown-category {
  &:deep() {
    .btn {
      @apply block h-[41.33px] min-h-[unset] rounded-none rounded-l-full py-2 shadow-none;
    }
  }
}
.caret-icon {
  @apply text-xs;
}
</style>
