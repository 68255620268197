<script setup lang="ts">
import SSLogo from "@/assets/sslogo.svg";
const profile = useProfileStore();
const app = useAppStore();
const lyricVideoMVPStore = useLyricVideoMVPStore();
const beta = useBetaStore();
const modals = useModalStore();
const { t } = useI18n();

const version = import.meta.env.VITE_BUILD_VERSION;
const year = computed(() => new Date().getFullYear());

const aTagRegEx = /<a[^\>]+>(.*)<\/a>/;

const copyrightNoticeObject = computed(() => {
  const text = t("S.LIT_CopyrightNotice");
  const regexMatch = text.match(aTagRegEx);
  if (!regexMatch || !regexMatch[0]) {
    return null;
  }

  const allowedElement = regexMatch[0];
  const startIndex = text.indexOf(allowedElement);
  const endIndex = text.indexOf(allowedElement) + allowedElement.length;

  const element = document.createElement("div");
  element.innerHTML = regexMatch[0];

  if (
    !element.children ||
    !element.children[0] ||
    !(element.children[0] as HTMLAnchorElement).href
  ) {
    return null;
  }

  const url = new URL((element.children[0] as HTMLAnchorElement).href);
  if (!url.host.includes("ccli.com")) {
    return null;
  }

  return {
    href: (element.children[0] as HTMLAnchorElement).href,
    innerText: element.innerText,
    beforeText: text.slice(0, startIndex),
    endText: text.slice(endIndex),
  };
});

const copyrightNotice = computed(() => {
  const text = t("S.LIT_CopyrightNotice");
  if (!copyrightNoticeObject.value) {
    return text;
  }
  const clensedText = text.replace(aTagRegEx, "{url}");
  return clensedText;
});

const enablePricing = computed(
  () => profile.profile?.featureToggles.enablePricing,
);
</script>

<template>
  <footer
    v-if="app.showFooter"
    id="SiteFooter"
  >
    <Container>
      <div class="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
        <div>
          <div>
            <router-link
              id="footer-logo-link"
              to="/"
            >
              <img
                :src="SSLogo"
                :alt="$t('SV.LIT_SongSelectLogo')"
                class="site-logo"
              />
            </router-link>
          </div>

          <div class="text-copyright">
            &copy; {{ year }}
            <span v-if="copyrightNoticeObject">
              {{ copyrightNoticeObject.beforeText }}

              <a
                :href="copyrightNoticeObject.href"
                target="_blank"
                class="copyright-link"
                >{{ copyrightNoticeObject.innerText }}</a
              >
              {{ copyrightNoticeObject.endText }}
            </span>
            <span v-else>{{ copyrightNotice }}</span>
          </div>
          <div class="version-desktop">
            <div class="text-loop">
              {{ $t("SV.MES_MultitracksLoopCommunityNotIncluded") }}
            </div>
            <div class="text-version">
              {{ version }} /
              {{ profile.profile?.machine || "MACHINE_UNKNOWN" }}
            </div>
          </div>
        </div>

        <div class="grid gap-6 xl:col-span-2 xl:grid-cols-2">
          <div>
            <div class="form-control">
              <i18n-t
                keypath="SV.LIT_LanguageHasMovedToPreferences"
                scope="global"
                tag="span"
              >
                <template #preferences>
                  <button
                    type="button"
                    class="link"
                    @click.stop.prevent="modals.showModal('PreferenceModal')"
                  >
                    {{ $t("SV.LIT_Preferences") }}
                  </button>
                </template>
              </i18n-t>
            </div>
          </div>

          <div>
            <nav class="footer-nav">
              <div>
                <a
                  id="footer-learn-more-link"
                  href="https://ccli.com/songselect/"
                  target="_blank"
                  class="footer-nav-link"
                  >{{ $t("G.LIT_LearnMore") }}
                  <span class="sr-only">about SongSelect</span></a
                >
                <router-link
                  v-if="enablePricing"
                  id="footer-pricing-link"
                  to="/about/pricing"
                  class="footer-nav-link"
                  >{{ $t("G.LIT_Pricing") }}</router-link
                >
                <router-link
                  id="footer-terms-of-use-link"
                  to="/about/termsofuse"
                  class="footer-nav-link"
                  >{{ $t("G.LIT_TermsOfUse") }}</router-link
                >
              </div>
              <div>
                <a
                  id="footer-privacy-policy-link"
                  href="https://ccli.com/privacy-policy/"
                  target="_blank"
                  class="footer-nav-link"
                  >{{ $t("G.LIT_PrivacyPolicy") }}</a
                >
                <a
                  id="footer-support-link"
                  href="https://ccli.com/support"
                  target="_blank"
                  class="footer-nav-link"
                  >{{ $t("G.LIT_Support") }}</a
                >
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="version-small">
        <div class="text-loop">
          {{ $t("SV.MES_MultitracksLoopCommunityNotIncluded") }}
        </div>
        <div class="text-version">
          {{ version }} / {{ profile.profile?.machine || "MACHINE_UNKNOWN" }}
        </div>
      </div>
    </Container>
  </footer>
</template>

<style scoped lang="postcss">
footer {
  @apply bg-base-100 py-20 text-gray-600 max-md:text-center;
}
#footer-logo-link {
  width: 250px;
  max-width: calc(100vw - 10px);
  height: 49px;
  @apply mb-4 inline-block;
}

.text-loop {
  @apply text-xs text-gray-600;
}

.text-version {
  @apply text-xs text-gray-200;
}

.text-copyright {
  @apply text-sm;
}

.footer-nav {
  @apply grid md:grid-cols-2 md:gap-5;

  a {
    @apply text-gray-600 transition-all;
  }
  a:hover {
    @apply text-gray-800 underline;
  }
}
.footer-nav-link {
  @apply mb-2 block;
}
.input-language {
  @apply -mt-1 inline-block w-64;
  :deep() {
    select {
      @apply w-64 bg-white;
    }
  }
}

.version-desktop {
  @apply hidden md:block;
}
.version-small {
  @apply mt-3 text-xs md:hidden;
}

.dark .site-logo {
  filter: brightness(0) invert(100);
}
</style>
